import { REF_KEYS } from "utils/constants";
import { getMatchingRefs } from "utils/lib";
import { MONTHS_DATA, PvWattsFields } from "./constants";
import { getMonth, getHours, isLeapYear, isWeekend, setHours, startOfYear, add, addHours, addYears, getDaysInMonth } from "date-fns";
import { std, mean } from "mathjs";

export const getAnnualDataByHour = (
  refs,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery,
  rateStructures,
  pvWattsData,
  batteryStartCharge
) => {
  const {
    siteConsumptionData,
    batteryQuantity,
    inverterData,
    dailyMinimumCycle: formDailyMinimumCycle,
    oldRateStructure,
    newRateStructure,
    solarQuantity,
  } = formData;

  const batteryCapacity = getBatteryCapacity(batteryQuantity, selectedBattery);
  const batteryChargeFloor = getBatteryChargeFloor(
    refs,
    formDailyMinimumCycle,
    batteryCapacity
  );
  let batteryCharge = batteryStartCharge || batteryCapacity * 0.5;

  // Amount the battery/inverter can discharge in this hour
  const totalInverterCapacity = getTotalInverterCapacity(
    inverterData,
    selectedInverters
  );

  //Set intial values for calculations
  let lastGeneratorDay = -1; //Save last day generator was run to only increment generator run days once at most every 24 hours
  const annualData = [];
  const startDate = getFirstHourOfCurrentYear();
  const endDate = addYears(startDate, 1);
  let currentHourAsDate = startDate;
  let hourIndex = 0; // We could continually calculate the distance of the current hour from the startDate, but this feels cleaner and is likely faster

  //Get monthly utility info
  const oldRateStructureData = rateStructures.find(structure => structure.label === oldRateStructure);
  const newRateStructureData = rateStructures.find(structure => structure.label === newRateStructure);
  const oldEnergyRates = oldRateStructureData ? buildAnnualRatesArray(oldRateStructureData, startDate) : null;
  const newEnergyRates = buildAnnualRatesArray(newRateStructureData, startDate);
  const netMeteringEnabled = formData.netMeteringEnabled;

  //Iterate over all hours of dc solar array output
  while (currentHourAsDate < endDate) {
    // Establish whether battery should be discharged
    const monthIndex = currentHourAsDate.getMonth();
    const hoursInMonth = getDaysInMonth(monthIndex) * 24;
    const monthlyConsumption = siteConsumptionData[monthIndex];
    const hourlyConsumption = monthlyConsumption / hoursInMonth;
    const oldImportRate = oldEnergyRates ? oldEnergyRates[hourIndex] : 0;
    const newImportRate = newEnergyRates[hourIndex];
    const exportRate = netMeteringEnabled ? newImportRate : newImportRate * 0.15; // exportRate will be independent from importRate at some point
    const combinedImportExportRate = newImportRate + exportRate;
    const currentHourRatesWindow = get18HourSlice(hourIndex, newEnergyRates);
    const currentHourRateRanking = getRankOfCurrentHour(currentHourRatesWindow); // Returns rank >= 1, not index
    const hoursOfChargeRemaining =
      (batteryCharge - batteryChargeFloor) / totalInverterCapacity; // At max discharge rate
    const unusedBatteryCapacity = batteryCapacity - batteryCharge;
    const shouldOffsetGridImport = determineShouldUseBatteryCharge(
      hoursOfChargeRemaining,
      currentHourRateRanking
    );
    const shouldMaximizeSaleToGrid = determineShouldMaximizeExport(
      shouldOffsetGridImport,
      combinedImportExportRate,
      currentHourRatesWindow
    );
    const hourlyEnergyUsage = siteConsumptionData[monthIndex] / hoursInMonth;

    const hourlyData = {
      siteConsumption: hourlyConsumption,
      solarOffset: 0,
      gridSellback: 0,
      gridExport: 0,
      gridConsumption: 0,
      genDay: false,
      systemConsumption: 0,
      dollarSavings: 0,
      dollarSellback: 0,
      batteryChargeConsumption: 0,
      batteryChargeCost: 0,
      editable: true,
      batteryCharge: 0,
      batteryChargeFromSolar: 0,
      oldEnergyCost: hourlyEnergyUsage * oldImportRate,
      newRawEnergyCost: hourlyEnergyUsage * newImportRate,
      totalCost: 0,
      newImportRate: newImportRate,
      finalCondition: "",
    };

    //Total Dc array output of PV system after clipping
    const solarOffset = getSolarOffset(
      pvWattsData[hourIndex],
      solarQuantity,
      selectedSolarModule,
      totalInverterCapacity
    );
    hourlyData.solarOffset += solarOffset;
    const systemIsRunningWhollyOnSolar = solarOffset > hourlyConsumption;
    const availableBatteryPower = batteryCharge - batteryChargeFloor;

    if (shouldOffsetGridImport) { // Energy is expensive
      const availableSystemEnergy = Math.min(
        solarOffset + availableBatteryPower,
        totalInverterCapacity
      );

      if (systemIsRunningWhollyOnSolar) {
        // Always consume demand from solar production
        updateHourlyDataWithSystemConsumption(hourlyConsumption, newImportRate, hourlyData)

        if (shouldMaximizeSaleToGrid) {
          const availableSystemEnergyAfterConsumption = availableSystemEnergy - hourlyConsumption;
          // Sell entirety of solar production and available battery charge
          batteryCharge -= Math.min(availableBatteryPower, availableSystemEnergyAfterConsumption);
          updateHourlyDataWithGridSale(availableSystemEnergyAfterConsumption, exportRate, hourlyData);
          hourlyData.finalCondition = "offsetImport_solarExceedsDemand_maximizeSellback";

        } else { // (!shouldMaximizeSaleToGrid)
          const excessSolar = solarOffset - hourlyConsumption;
          const excessSolarAfterBatteryCharge = excessSolar - unusedBatteryCapacity;
          if (excessSolarAfterBatteryCharge > 0) {
            batteryCharge = batteryCapacity;
            updateHourlyDataWithBatteryChargeFromSolar(unusedBatteryCapacity, hourlyData);
            updateHourlyDataWithGridSale(excessSolarAfterBatteryCharge, exportRate, hourlyData);
            hourlyData.finalCondition = "offsetImport_solarExceedsDemand_notThrottled_sellExcess";
          } else {
            batteryCharge += excessSolar;
            updateHourlyDataWithBatteryChargeFromSolar(excessSolar, hourlyData);
            hourlyData.finalCondition = "offsetImport_solarExceedsDemand_throttled_chargeFromProductionExcess";
          }
        }
      } else { // (!systemIsRunningWhollyOnSolar)
        const requiredEnergyDemandBeyondSolarProduction = hourlyConsumption - solarOffset;

        if (requiredEnergyDemandBeyondSolarProduction > availableBatteryPower) {
          lastGeneratorDay = checkIncrementLastGeneratorDay(lastGeneratorDay, hourIndex, hourlyData);
          // Not enough combined battery and solar to cover demand, so we consume all available energy and buy remainder from grid
          const remainingEnergyDemand = requiredEnergyDemandBeyondSolarProduction - availableBatteryPower;
          updateHourlyDataWithGridConsumption(remainingEnergyDemand, newImportRate, hourlyData)
          updateHourlyDataWithSystemConsumption(availableBatteryPower, newImportRate, hourlyData);
          batteryCharge = batteryChargeFloor;
          hourlyData.finalCondition = "offsetImport_demandNotMetBySystem_coverDeficitWithGridPurchase"
        } else {
          // Battery covers production deficit, so we discharge battery to meet demand
          updateHourlyDataWithSystemConsumption(requiredEnergyDemandBeyondSolarProduction, newImportRate, hourlyData);
          batteryCharge -= requiredEnergyDemandBeyondSolarProduction;
          hourlyData.finalCondition = "offsetImport_demandMetBySystem_coverWithSolarAndBattery";
        }

      }
    } else { // (!shouldOffsetGridImport) -- Energy is cheap
      const maxNeededPower = unusedBatteryCapacity + hourlyConsumption;

      if (systemIsRunningWhollyOnSolar) {
        const excessSolar = solarOffset - hourlyConsumption;
        const remainingInverterCapacity = totalInverterCapacity - hourlyConsumption;
        updateHourlyDataWithSystemConsumption(hourlyConsumption, newImportRate, hourlyData);

        if (maxNeededPower < totalInverterCapacity) { // Inverter large enough to fully charge battery

          if (excessSolar > unusedBatteryCapacity) {
            // Fully charge battery from solar and sell back excess
            const unusedBatteryCapacity = batteryCapacity - batteryCharge;
            batteryCharge = batteryCapacity;
            const excessSolarAfterBatteryCharge = excessSolar - unusedBatteryCapacity;
            updateHourlyDataWithBatteryChargeFromSolar(unusedBatteryCapacity, hourlyData);
            updateHourlyDataWithGridSale(excessSolarAfterBatteryCharge, exportRate, hourlyData);
            hourlyData.finalCondition = "notOffsetImport_solarExceedsDemand_notThrottled_fullChargeAndSaleOfExcessSolar";
          } else {
            // Charge battery to full by combination of solar and grid charging
            const chargeFromGrid = batteryCapacity - (batteryCharge + solarOffset);
            batteryCharge = batteryCapacity;
            updateHourlyDataWithBatteryChargeFromSolar(solarOffset, hourlyData);
            updateHourlyDataWithBatteryChargeFromGrid(chargeFromGrid, newImportRate, hourlyData);
            hourlyData.finalCondition = "notOffsetImport_solarExceedsDemand_notThrottled_partialChargeBySolar";
          }

        } else { // We'd be throttled by inverter

          const shouldChargeFromGrid = (
            hourlyConsumption < totalInverterCapacity && 
            unusedBatteryCapacity > excessSolar &&
            excessSolar < remainingInverterCapacity
          );

          if (shouldChargeFromGrid) {
            // Demand is met, so grid charge by remaining inverter capacity
            lastGeneratorDay = checkIncrementLastGeneratorDay(lastGeneratorDay, hourIndex, hourlyData);
            const chargeFromGrid = remainingInverterCapacity - excessSolar;
            batteryCharge += remainingInverterCapacity;
            updateHourlyDataWithBatteryChargeFromSolar(excessSolar, hourlyData);
            updateHourlyDataWithBatteryChargeFromGrid(chargeFromGrid, newImportRate, hourlyData)
            hourlyData.finalCondition = "notOffsetImport_solarExceedsDemand_throttled_chargeFromExcessSolarAndGrid";
          } else {
            // Charge only from solar array
            batteryCharge += excessSolar;
            updateHourlyDataWithBatteryChargeFromSolar(solarOffset, hourlyData);
            hourlyData.finalCondition = "notOffsetImport_solarExceedsDemand_throttled_chargeFromExcessSolarOnly";
          }

        }
      } else { // (!shouldOffsetGridImport && !systemIsRunningWhollyOnSolar)
        // Meet demand with combination of solar and grid purchase, and grid charge for remainder of inverter capacity
        lastGeneratorDay = checkIncrementLastGeneratorDay(lastGeneratorDay, hourIndex, hourlyData);
        const requiredDemandBeyondSolarProduction = hourlyConsumption - solarOffset;
        updateHourlyDataWithSystemConsumption(solarOffset, newImportRate, hourlyData);
        updateHourlyDataWithGridConsumption(requiredDemandBeyondSolarProduction, newImportRate, hourlyData);

        const inverterCapacityBeyondDemand = totalInverterCapacity > hourlyConsumption ? totalInverterCapacity - hourlyConsumption : 0;
        const batteryChargeFromGrid = Math.min(
          unusedBatteryCapacity,
          inverterCapacityBeyondDemand
        );

        batteryCharge += batteryChargeFromGrid;
        updateHourlyDataWithBatteryChargeFromGrid(batteryChargeFromGrid, newImportRate, hourlyData);
        hourlyData.finalCondition = "notOffsetImport_demandExceedsProduction_meetDemandWithBatteryAndGridPurchase_gridCharge";
      }
    }

    // For analysis purposes - not rendered in final table
    hourlyData.batteryCharge = batteryCharge;

    annualData.push(hourlyData);
    currentHourAsDate = addHours(currentHourAsDate, 1);
    hourIndex++;
  }

  return annualData;
};

export const compileMonthlyData = (annualDataByHour, siteConsumptionData) => {
  const annualDataInMonths = [];

  for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const hoursInCurrentMonth = getHoursInMonth(monthIndex);

    const monthlyData = {
      month: MONTHS_DATA[monthIndex].month,
      siteConsumption: siteConsumptionData
        ? Number(siteConsumptionData[monthIndex])
        : 1550, // In the quick designer, this value is always coming up 1550
      solarOffset: 0,
      gridSellback: 0,
      gridConsumption: 0,
      netGridExport: 0,
      genDays: 0,
      systemConsumption: 0,
      batteryEndCharge: 0,
      dollarSavings: 0,
      netDollarSavings: 0,
      dollarSellback: 0,
      batteryChargeConsumption: 0,
      batteryChargeCost: 0,
      batteryChargeFromSolar: 0,
      oldEnergyCost: 0,
      newRawEnergyCost: 0,
      totalCost: 0,
      editable: true,
    };

    let startHour = MONTHS_DATA[monthIndex].startHour;

    for (let hour = startHour; hour < startHour + hoursInCurrentMonth; hour++) {
      monthlyData[PvWattsFields.SolarOffset] += annualDataByHour[hour][PvWattsFields.SolarOffset];
      monthlyData[PvWattsFields.GridSellback] += annualDataByHour[hour][PvWattsFields.GridSellback];
      monthlyData[PvWattsFields.GridConsumption] += annualDataByHour[hour][PvWattsFields.GridConsumption];
      if (annualDataByHour[hour].genDay) monthlyData[PvWattsFields.GenDays]++;
      monthlyData[PvWattsFields.NetGridExport] +=
        annualDataByHour[hour][PvWattsFields.GridExport] -
        annualDataByHour[hour][PvWattsFields.BatteryChargeConsumption]
      monthlyData[PvWattsFields.SystemConsumption] += annualDataByHour[hour][PvWattsFields.SystemConsumption];
      monthlyData[PvWattsFields.DollarSavings] += annualDataByHour[hour][PvWattsFields.DollarSavings];
      monthlyData[PvWattsFields.NetDollarSavings] +=
        annualDataByHour[hour][PvWattsFields.DollarSavings] -
        annualDataByHour[hour][PvWattsFields.BatteryChargeCost];
      monthlyData[PvWattsFields.DollarSellback] += annualDataByHour[hour][PvWattsFields.DollarSellback];
      monthlyData[PvWattsFields.BatteryChargeConsumption] += annualDataByHour[hour][PvWattsFields.BatteryChargeConsumption];
      monthlyData[PvWattsFields.BatteryChargeCost] += annualDataByHour[hour][PvWattsFields.BatteryChargeCost];
      monthlyData[PvWattsFields.BatteryChargeFromSolar] += annualDataByHour[hour][PvWattsFields.BatteryChargeFromSolar];
      monthlyData[PvWattsFields.OldEnergyCost] += annualDataByHour[hour][PvWattsFields.OldEnergyCost];
      monthlyData[PvWattsFields.NewRawEnergyCost] += annualDataByHour[hour][PvWattsFields.NewRawEnergyCost];
      monthlyData[PvWattsFields.TotalCost] += annualDataByHour[hour][PvWattsFields.TotalCost];
    }

    annualDataInMonths.push(monthlyData);
  }

  // Since we have no data for leap day, we'll duplicate Feb 28th and add it again
  if (isLeapYear(new Date())) {
    addAdditionalDayOfFebruaryRates(annualDataInMonths, annualDataByHour);
  }

  return annualDataInMonths;
};

export const isDataComplete = (pvWattsData) => {
  return (
    pvWattsData !== undefined &&
    pvWattsData[1] !== undefined &&
    pvWattsData.length > 0
  );
};

export const getHoursInMonth = (monthIndex) => {
  return MONTHS_DATA[monthIndex].daysInMonth * 24;
};

export const getBatteryCapacity = (quantity, battery) => {
  return battery.capacityKwh
    ? quantity * battery.capacityKwh
    : quantity * battery.value;
};

export const getBatteryChargeFloor = (
  refs,
  formDailyMinimumCycle,
  batteryCapacity
) => {
  const dailyMinimumCycleOptions = getMatchingRefs(
    refs,
    REF_KEYS.DailyMinimumCycleOptions
  );
  const dailyMinimumCycle = dailyMinimumCycleOptions.find(
    (datum) => datum.id === formDailyMinimumCycle
  ).value;
  return dailyMinimumCycle * batteryCapacity;
};

export const getTotalInverterCapacity = (inverterData, inverters) => {
  for (let i = 0; i < inverterData.length; i++) {
    const inverter = inverters.find(
      (inverter) => inverter.id === inverterData[i].id
    );
    const quantity = inverterData[i].quantity;
    const capacity = inverter
      ? inverter.gridNameplateCapacityKw
        ? inverter.gridNameplateCapacityKw
        : inverter.value
      : 0;
    return quantity * capacity;
  }
};

export const getSolarOffset = (
  pvWattsData,
  solarQuantity,
  solarModule,
  totalInverterCapacity
) => {
  const solarModuleWatts = solarModule.sizeWatt
    ? solarModule.sizeWatt / 1000000
    : solarModule.value / 1000;

  const totalSolarProduction = pvWattsData * solarQuantity * solarModuleWatts;

  return totalSolarProduction > totalInverterCapacity
    ? totalInverterCapacity
    : totalSolarProduction;
};

// Hacky approach to manufacturing leap year data if we don't actually have it... unnecessary?
const addAdditionalDayOfFebruaryRates = (
  annualDataInMonths,
  annualDataByHour
) => {
  const endHour = MONTHS_DATA[2].startHour; // First hour of March 1
  const startHour = endHour - 24; // First hour of Feb 28

  for (let hour = startHour; hour < endHour; hour++) {
    annualDataInMonths[1][PvWattsFields.SolarOffset] += annualDataByHour[hour][PvWattsFields.SolarOffset];
    annualDataInMonths[1][PvWattsFields.GridSellback] += annualDataByHour[hour][PvWattsFields.GridSellback];
    annualDataInMonths[1][PvWattsFields.GridConsumption] += annualDataByHour[hour][PvWattsFields.GridConsumption];
    if (annualDataByHour[hour].genDay) annualDataInMonths[1][PvWattsFields.GenDays]++;
    annualDataInMonths[1][PvWattsFields.SystemConsumption] += annualDataByHour[hour][PvWattsFields.SystemConsumption];
    annualDataInMonths[1][PvWattsFields.DollarSavings] += annualDataByHour[hour][PvWattsFields.DollarSavings];
    annualDataInMonths[1][PvWattsFields.DollarSellback] += annualDataByHour[hour][PvWattsFields.DollarSellback];
  }
};

const getFirstHourOfCurrentYear = () => {
  const now = new Date(2023, 0, 1);
  const startOfCurrentYear = startOfYear(now);
  const firstHourOfCurrentYear = setHours(startOfCurrentYear, 0);

  return firstHourOfCurrentYear;
};

const buildAnnualRatesArray = (rateStructureData, firstHourOfYear) => {
  const weekendRates = rateStructureData.energyweekendschedule;
  const weekdayRates = rateStructureData.energyweekdayschedule;

  const annualRatesArray = [];
  let currentHour = firstHourOfYear;

  // Add 18 extra hours to account for sliding window in further calculations
  const endDate = add(firstHourOfYear, {
    years: 1,
    hours: 18,
  });

  const rateValues = [];
  for (let i = 0; i < rateStructureData.energyratestructure.length; i++) {
    rateValues.push(rateStructureData.energyratestructure[i][0].rate);
  }

  while (currentHour < endDate) {
    const month = getMonth(currentHour);
    const hour = getHours(currentHour);
    // weekendRates store indices corresponding to values in rateValues array
    // Future data will include export values, but for now we are only concerned with import
    annualRatesArray.push(
      isWeekend(currentHour)
        ? rateValues[weekendRates[month][hour]]
        : rateValues[weekdayRates[month][hour]]
    );
    currentHour = addHours(currentHour, 1);
  }

  return annualRatesArray;
};

const get18HourSlice = (hourIndex, energyRates) => {
  return energyRates.slice(hourIndex, hourIndex + 18);
};

// Determines rank of current hour's rate compared against rates of next 18 hours
const getRankOfCurrentHour = (ratesWindow) => {
  const currentRate = ratesWindow[0];
  return 1 + ratesWindow.sort((a, b) => b - a).indexOf(currentRate);
};

const determineShouldUseBatteryCharge = (
  hoursOfChargeRemaining,
  currentHourRateRanking
) => {
  if (hoursOfChargeRemaining === 0) {
    return false;
  }

  if (currentHourRateRanking === 1) {
    return true;
  }

  return currentHourRateRanking <= hoursOfChargeRemaining;
};

const determineShouldMaximizeExport = (
  shouldSellBatteryCharge,
  combinedImportExportRate,
  currentHourRatesWindow
) => {
  if (!shouldSellBatteryCharge) {
    return false;
  }

  return (
    combinedImportExportRate >=
    2 * (mean(currentHourRatesWindow) - std(currentHourRatesWindow))
  );
};

const checkIncrementLastGeneratorDay = (
  lastGeneratorDay,
  hourIndex,
  hourlyData
) => {
  if (lastGeneratorDay !== Math.floor(hourIndex / 24)) {
    lastGeneratorDay = Math.floor(hourIndex / 24);
    hourlyData.genDay = true;
  }

  return lastGeneratorDay;
};

const updateHourlyDataWithGridSale = (amountkWh, exportRate, hourlyData) => {
  const total = amountkWh * exportRate;
  hourlyData[PvWattsFields.DollarSellback] += total;
  hourlyData[PvWattsFields.GridSellback] += amountkWh;
  hourlyData[PvWattsFields.GridExport] += amountkWh;
  hourlyData[PvWattsFields.TotalCost] -= total;
};

const updateHourlyDataWithSystemConsumption = (
  amount,
  importRate,
  hourlyData
) => {
  const totalSavings = amount * importRate
  hourlyData[PvWattsFields.SystemConsumption] += amount;
  hourlyData[PvWattsFields.DollarSavings] += totalSavings;
  hourlyData[PvWattsFields.TotalCost] -= totalSavings;
};

const updateHourlyDataWithGridConsumption = (amount, importRate, hourlyData) => {
  hourlyData[PvWattsFields.GridConsumption] += amount;
  hourlyData[PvWattsFields.TotalCost] += amount * importRate;
};

const updateHourlyDataWithBatteryChargeFromGrid = (
  amount,
  importRate,
  hourlyData
) => {
  const total = amount * importRate;
  hourlyData[PvWattsFields.BatteryChargeConsumption] += amount;
  hourlyData[PvWattsFields.BatteryChargeCost] += total;
  hourlyData[PvWattsFields.TotalCost] += total;
};

const updateHourlyDataWithBatteryChargeFromSolar = (
  amount,
  hourlyData
) => {
  hourlyData[PvWattsFields.BatteryChargeFromSolar] += amount;
};
