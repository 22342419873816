import * as yup from "yup";

const teamCodeRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;

export const CREATE_OR_UPDATE_TEAM_SCHEMA = yup
  .object({
    name: yup
      .string()
      .required("Team name is required")
      .max(32, "Team name must be 32 characters or less"),
    description: yup
      .string()
      .max(160, "Description must be 160 characters or less"),
    teamCode: yup
      .string()
      .matches(
        teamCodeRegex,
        "Team code must be alphanumeric characters with optional internal hyphens"
      ),
    embedConfig: yup.object({
      skipRateSelectionPage: yup.boolean(),
      hideSystemDesignSection: yup.boolean(),
      hideEstimatesSection: yup.boolean(),
      hideBillInfoSection: yup.boolean(),
      hideRateSelectionResultsPage: yup.boolean(),
      hideElectricBillAndRateValuesResultsPage: yup.boolean(),
      hideKwhUseResultsPage: yup.boolean(),
      hidePerformanceChart: yup.boolean(),
      hideSystemDetailsChart: yup.boolean(),
      defaultSolarModule: yup.object({
        quantity: yup
          .number()
          .required("Solar module quantity is required")
          .min(1, "Solar module quantity must be greater than 0")
          .max(96, "Solar module quantity must be less than or equal to 96"),
        value: yup
          .number()
          .required("Solar module value is required")
          .min(0.1, "Solar module value must be greater than 0"),
      }),
      defaultBattery: yup.object({
        quantity: yup
          .number()
          .required("Battery quantity is required")
          .min(1, "Battery quantity must be greater than 0")
          .max(20, "Battery quantity must be less than or equal to 20"),
        value: yup
          .number()
          .required("Battery value is required")
          .min(1, "Battery value must be 1 or greater"),
      }),
    }),
  })
  .required();
