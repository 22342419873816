// First hour of each month when year is broken into hours
export const MONTHS_DATA = [
  { month: "January", startHour: 0, daysInMonth: 31 },
  { month: "February", startHour: 744, daysInMonth: 28 },
  { month: "March", startHour: 1416, daysInMonth: 31 },
  { month: "April", startHour: 2160, daysInMonth: 30 },
  { month: "May", startHour: 2880, daysInMonth: 31 },
  { month: "June", startHour: 3624, daysInMonth: 30 },
  { month: "July", startHour: 4344, daysInMonth: 31 },
  { month: "August", startHour: 5088, daysInMonth: 31 },
  { month: "September", startHour: 5832, daysInMonth: 30 },
  { month: "October", startHour: 6552, daysInMonth: 31 },
  { month: "November", startHour: 7296, daysInMonth: 30 },
  { month: "December", startHour: 8016, daysInMonth: 31 },
];

export const PvWattsFields = {
  BatteryCharge: "batteryCharge",
  BatteryChargeConsumption: "batteryChargeConsumption",
  BatteryChargeCost: "batteryChargeCost",
  BatteryChargeFromSolar: "batteryChargeFromSolar",
  DollarSavings: "dollarSavings",
  DollarSellback: "dollarSellback",
  Editable: "editable",
  FinalCondition: "finalCondition",
  GenDays: "genDays",
  GridConsumption: "gridConsumption",
  GridExport: "gridExport",
  GridSellback: "gridSellback",
  Month: "month",
  NetDollarSavings: "netDollarSavings",
  NetGridExport: "netGridExport",
  NewDemand: "newDemand",
  NewImportRate: "newImportRate",
  NewRawEnergyCost: "newRawEnergyCost",
  OldEnergyCost: "oldEnergyCost",
  SiteConsumption: "siteConsumption",
  SolarOffset: "solarOffset",
  SystemConsumption: "systemConsumption",
  TotalCost: "totalCost",
};
