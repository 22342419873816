import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { REF_KEYS } from "utils/constants";
import { getMatchingRefs } from "utils/lib";

export const fetchAndDownloadCsv = async (
  refs,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery,
  rateStructures,
  pvWattsData,
  batteryStartCharge,
  api
) => {
  try {
    const response = await api.post("energyFlow/getEnergyFlowCsv", {
      refs,
      formData,
      selectedSolarModule,
      selectedInverters,
      selectedBattery,
      rateStructures,
      pvWattsData,
      batteryStartCharge,
    });

    const jsonResponse = response.data;

    if (!jsonResponse.success) {
      throw new Error("Failed to fetch CSV data");
    }

    const csvContent = jsonResponse.data.csvContent;

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "energy_flow_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading CSV:", error);
    throw new Error("Failed to download CSV");
  }
};

const renderPdf = (element) => {
  return new Promise((resolve, reject) => {
    const pdf = new jsPDF("portrait", "px", "letter");
    const options = {};

    html2canvas(element, options)
      .then((canvas) => {
        const img = canvas.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(img, "PNG", 15, 5, pdfWidth - 30, pdfHeight - 40);
        resolve(pdf.output("blob"));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDesignPdfBlob = async (emailReportRef) => {
  return new Promise((resolve, reject) => {
    const pdfElement = emailReportRef.current;

    const observer = new MutationObserver(() => {
      if (pdfElement.offsetWidth > 0 && pdfElement.offsetHeight > 0) {
        observer.disconnect();
        renderPdf(pdfElement)
          .then(resolve)
          .catch(reject);
      }
    });

    observer.observe(pdfElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Fallback in case element isn't rendering for some reason
    setTimeout(() => {
      observer.disconnect();
      if (pdfElement.offsetWidth > 0 && pdfElement.offsetHeight > 0) {
        renderPdf(pdfElement).then(resolve).catch(reject);
      } else {
        reject(new Error("Element failed to render within timeout"));
      }
    }, 5000);
  });
}

export async function sendDesignPdf(email, pdfBlob, api) {
  const formData = new FormData();
  formData.append("email", email);

  formData.append("designPdf", pdfBlob);

  api.post("lead/sendDesignPdf/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function signUserUp(formData, rateStructures, refs, designPdfBlob, api) {
  try {
    const requestData = getSignupRequestData(formData, rateStructures, refs);
    requestData.append("designPdf", designPdfBlob);

    await api.post("lead/create/", requestData);
  } catch (errors) {
    return Promise.reject(errors);
  }
};

const getSignupRequestData = (formData, rateStructures, refs) => {
  const {
    signupNotes,
    embedUuid,
  } = formData;


  const userData = getFormattedUserDataFromFormData(formData);
  const designSpecs = getFormattedDesignSpecsFromFormData(formData, rateStructures, refs);

  const requestPayload = {
    userData,
    designSpecs,
    signupNotes: signupNotes || "",
    embedId: embedUuid || "default",
    createdBy: formData.email,
    modifiedBy: formData.email,
  };

  const reqFormData = new FormData();
  reqFormData.append("requestData", JSON.stringify(requestPayload));

  return reqFormData;
};

const getFormattedUserDataFromFormData = (formData) => {
  const {
    firstName,
    lastName,
    email,
    address,
    iAmThe,
    iWorkIn,
    agreedToTerms,
  } = formData;

  return {
    firstName,
    lastName,
    email,
    address,
    iAmThe,
    iWorkIn,
    agreedToTerms,
  }
}

const getFormattedDesignSpecsFromFormData = (formData, rateStructures, refs) => {
  const {
    latitude,
    longitude,
    zipCode,
    oldRateStructure,
    newRateStructure,
    netMeteringEnabled: netMeteringChecked,
    siteConsumptionData,
    solarModule,
    solarQuantity,
    inverterData,
    battery,
    batteryQuantity,
    totalProjectCost,
    downPayment,
    taxCredit,
    otherIncentives,
    financingInterestRate,
    financingTerm,
    financedAmount,
  } = formData;

  const inverterRefId = inverterData[0].id;

  const oldRateStructureName = rateStructures.find(rateStructure => rateStructure.label === oldRateStructure)?.name;
  const newRateStructureName = rateStructures.find(rateStructure => rateStructure.label === newRateStructure)?.name;

  const batteryRefs = getMatchingRefs(refs, REF_KEYS.BasicBatteryOptions);
  const inverterRefs = getMatchingRefs(refs, REF_KEYS.BasicInverterOptions);
  const solarModuleRefs = getMatchingRefs(refs, REF_KEYS.BasicSolarOptions);

  const batteryValue = batteryRefs.find(ref => ref.id === battery)?.value;
  const solarModuleValue = solarModuleRefs.find(ref => ref.id === solarModule)?.value;
  const inverterValue = inverterRefs.find(ref => ref.id === inverterRefId)?.value;

  return {
    location: {
      lat: latitude,
      long: longitude,
      zip: zipCode,
    },
    oldRateStructure: oldRateStructureName,
    newRateStructure: newRateStructureName,
    netMeteringChecked,
    siteConsumptionData,
    solarModule: { value: solarModuleValue * 1000, quantity: solarQuantity },
    inverters: [{ value: inverterValue, quantity: 1 }],
    battery: { value: batteryValue, quantity: batteryQuantity },
    financials: {
      totalProjectCost,
      downPayment,
      taxCredit,
      otherIncentives,
      financingInterestRate,
      financingTerm,
      financedAmount,
    },
  };
}
