export const REF_KEYS = {
  BasicBatteryOptions: "basicBatteryOptions",
  BasicInverterOptions: "basicInverterOptions",
  BasicSolarOptions: "basicSolarOptions",
  BatteryMountStyleOptions: "batteryMountStyleOptions",
  BatteryRackingSystemOptions: "batteryRackingSystemOptions",
  BillRateOptions: "billRateOptions",
  ConsumptionRateOptions: "consumptionRateOptions",
  DailyMinimumCycleOptions: "dailyMinimumCycleOptions",
  EnvironmentOptions: "environmentOptions",
  IpRatingOptions: "ipRatingOptions",
  NemaRatingOptions: "nemaRatingOptions",
  OcpdTypeOptions: "ocpdTypeOptions",
  PortTypeOptions: "portTypeOptions",
};
