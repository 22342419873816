 export const defaultBattery = {
  //Basics
  manufacturer: '',
  model: '',
  msrp: '',
  chemistry: '',
  capacityKwh: '',
  nominalVoltage: '',
  seriesMax: 0,
  parallelMax: 0,
  lengthInch: '',
  widthInch: '',
  depthInch: '',
  weightPound: '',
  //Racking
  mountStyles: [],
  compatibleRackingSystem: '',
  includesHeater: false,
  //Site requirements
  environment: '',
  ipRating: '',
  nemaRating: '',
  warrantedElevationFoot: '',
  ul9540: false,
  //BMS Features
  inverterCommunications: '',
  hubs: '',
  ocpdTypes: [],
  ocpdAmp: '',
  //Settings
  chargeMaxKw: '',
  dischargeMaxKw: '',
  vmChargeVoltage: '',
  pmChargeVoltage: '',
  chargeMaxVoltage: '',
  equalizationVoltage: '',
  enableEqualization: false,
  //Wiring
  terminalTorqueNm: '',
  portQuantity: '',
  terminalSize: '',
  portType: '',
  cableMin: '',
  cableMax: '2/0',
  //Warranty
  warrantyYears: 10,
  warrantyCycleRange: '',
  warrantyCycles: '',
  warrantyRangeEnd: '',
  warrantyThroughputRatio: '',
  createdOn: '',
  modifiedOn: ''
}

export const defaultInverter = {
  //Basics
  manufacturer: '',
  model: '',
  msrp: '',
  parallelMax: '',
  gridNameplateCapacityKw: '',
  acDcCoupledOutputMaxKw: '',
  gridBatteryMaxKw: '',
  transformer: false,
  splitPhase: false,
  lengthInch: '',
  widthInch: '',
  heightInch: '',
  weightPound: '',
  idleConsumptionWatt: '',
  //Installation (?)
  mountStyle: 'Wall',
  cabinets: 'Flextower',
  cabinet: false,
  environment: '',
  ipRating: '',
  nemaRating: '',
  warrantedElevationFoot: '',
  clearanceVerticalMinInch: '',
  clearanceHorizontalMinInch: '',
  //Communications
  batteryCommunication: false,
  batteryCommunications: '',
  mlpeHubs: '',
  mlpeHubIncluded: false,
  hubs: '',
  //Wiring
  gridTerminalTorqueNm: '',
  groundTerminalTorqueNm: '',
  neutralTerminalTorqueNm: '',
  generatorTerminalTorqueNm: '',
  loadTerminalTorqueNm: '',
  coverTerminalTorqueNm: '',
  batteryTerminalTorqueNm: '',
  batteryOcpdType: 'Switch',
  batteryOcpdAmp: '',
  batteryPortType: '',
  batteryPortQuantity: '',
  batteryTerminalSize: 'm8',
  batteryCableMin: '1/0',
  batteryCableMax: '4/0',
  batteryNominalVoltList: '',
  batteryMaxVolt: '',
  batteryMinKwh: '',
  batteryMaxKwh: '',
  batterySurgeMax100MsKw: '',
  batterySurgeMax10SKw: '',
  powerFactorMin: '',
  //Solar
  solarOutputMaxKw: '',
  solarOcpdType: '',
  solarOcpdSizeAmp: '',
  solarMaxVolt: '',
  solarPortType: 'Port',
  startingVolt: '',
  stringsMax: '',
  mpptQuantity: '',
  mppt1MaxAmp: '',
  mppt2MaxAmp: '',
  mppt3MaxAmp: '',
  mppt4MaxAmp: '',
  //Grid component
  cecEfficiency: '',
  gridPassthrough: false,
  gridOutputKw: '',
  gridPassthroughAmp: '',
  switchTimeMs: '',
  gridVolt: '',
  internalBypass: false,
  zeroOutflow: false,
  //Generator component
  generatorAmp: '',
  generatorPort: false,
  //Warranty
  warrantyYears: '',
};

export const defaultSolarModule = {
  //Basics
  manufacturer: '',
  model: '',
  msrp: '',
  sizeWatt: '',
  cellQuantity: '',
  warrantedElevationFoot: '',
  maxVolt: '',
  temperatureLowF: '',
  temperatureHighF: '',
  wattageTolerance: '',
  //Electrical
  ptcWatt: '',
  efficiency: '',
  calculationValues: 'STC',
  coefficientAmp: '',
  coefficientPower: '',
  coefficientVolt: '',
  cellTempNormalC: '',
  ampShortCircuit: '',
  voltOpenCircuit: '',
  //Mechanical
  palletWeightPound: '',
  snowPsf: '',
  windPsf: '',
  depthInch: '',
  lengthInch: '',
  widthInch: '',
  palletLwhInch: '71x42.5x48',
  weightPound: '',
  palletQuantity: '',
  //Wiring
  fuseMaxAmp: '',
  cableSizeAwg: '',
  whipLocation: 'Center',
  portType: 'Staubli MC4',
  whipNegativeInch: '',
  whipPositiveInch: '',
  //Warranty
  warrantyYear: '',
  warrantyDegradationAnnual: '',
  warrantyDegradationEnd: '',
  warrantyDegradationYear1: '',
  createdOn: '',
  modifiedOn: '',
  createdBy: '',
  modifiedBy: ''
}

export const defaultFreeDesign = {
  zipCode: '',
  coords: '',
  latitude: '',
  longitude: '',
  projectAltitude: '',
  projectTemperatureMin: '',
  projectTemperatureMax: '',
  monthlyElectricBill: '',
  monthlyConsumptionKwh: '',
  averageBillPerKwh: '',
  solarQuantity: 30,
  solarModule: '646250ef35241c370880f9a7',
  inverterData: [
    {
      id: '64132ec97f5834e7e3830126',
      quantity: 1
    }
  ],
  batteryQuantity: 4,
  oldRateStructure: '',
  newRateStructure: '',
  netMeteringEnabled: false,
  battery: '65bc08317b30e89137fe38e3',
  systemLossPercentage: 0.14,
  dailyMinimumCycle: '64188b950ee1cd831f0d9f46',
  siteConsumptionData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
  email: '',
  firstName: '',
  lastName: '',
  iAmThe: '',
  iWorkIn: '',
  signupNotes: '',
  agreedToTerms: true,
  totalProjectCost: 0,
  suggestTotalProjectCost: false,
  downPayment: 0,
  suggestDownPayment: false,
  suggestTaxCredit: false,
  taxCredit: 0,
  otherIncentives: 0,
  financingInterestRate: 6.99,
  financingTerm: 15
};

export const defaultAdvancedDesign = {
  owner: '',
  projectName: '',
  zipCode: '',
  coords: '',
  latitude: '',
  longitude: '',
  projectAltitude: '',
  projectTemperatureMin: '',
  projectTemperatureMax: '',
  billOrConsumption: 'bill',
  bill: '',
  billRate: 1,
  consumption: '',
  consumptionRate: 1,
  averageBillPerKwh: '',
  siteConsumptionData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1],
  solarQuantity: 36,
  solarManufacturer: '',
  solarModule: '',
  batteryQuantity: 4,
  batteryManufacturer: '',
  battery: '',
  inverterData: [
    {
      id: '',
      manufacturer: '',
      quantity: 1
    }
  ],
  systemLossPercentage: 0.14,
  dailyMinimumCycle: '64188b950ee1cd831f0d9f46'
};

export const defaultTeam = {
  name: '',
  description: '',
  owner: '',
  members: []
}