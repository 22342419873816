//To do: add radio button to switch from electric bill to kWh
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DropdownInput from 'components/inputs/dropdown.jsx';
import { buildRateNamesArray, getRateStructureData, getUniqueRateNames, isTouRateSelected } from './rate-selection-utils.js';
import SingleCheckbox from 'components/inputs/single-checkbox.jsx';
import { NET_METERING_TOOLTIP_TEXT } from './rate-selection-constants.js';
import Col from 'react-bootstrap/esm/Col.js';
import useDeviceSize from 'hooks/device-size.jsx';

function RateSelection({
  rateStructures,
  formData,
  setFieldValue,
  resultsPage
}) {
  const { isMobile } = useDeviceSize();
  const [netMeteringChecked, setNetMeteringChecked] = useState(formData.netMeteringEnabled || false);

  const rateNames = buildRateNamesArray(rateStructures);
  const uniqueRateNames = getUniqueRateNames(rateNames);

  const handleRateSelectChange = (e) => {
    const rateStructureLabel = e.target.value;
    const selectedRateStructureData = getRateStructureData(
      rateStructureLabel,
      rateStructures
    );
    const touRateSelected = isTouRateSelected(selectedRateStructureData);

    setFieldValue({
      newRateStructure: rateStructureLabel,
      touRateSelected,
    });
  }

  return (
    <Container>
      <Row>
        <DropdownInput
          htmlName="old-rate-structure"
          displayName="Old Rate Structure"
          fieldName="oldRateStructure"
          tooltip={`Select your current utility rate structure to see comparative data.${resultsPage ? "" : " If you are unsure of your current utility rate, you can leave this blank."}`}
          value={formData.oldRateStructure}
          options={uniqueRateNames}
          setFieldValue={setFieldValue}
          colWidth={6}
        />
        <DropdownInput
          htmlName="new-rate-structure"
          displayName="New Rate Structure"
          fieldName="newRateStructure"
          tooltip="Select your planned utility rate structure."
          value={formData.newRateStructure}
          options={uniqueRateNames}
          setFieldValue={setFieldValue}
          colWidth={6}
          onChange={handleRateSelectChange}
        />
        {!resultsPage && (
          <Row>
            <Col xs={isMobile ? 12 : 6} />
            <Col style={{ paddingLeft: "24px" }} xs={isMobile ? 12: 6}>
              <SingleCheckbox
                id="net-metering-checkbox"
                label="Use Net Metering Rates"
                checked={netMeteringChecked}
                onChange={(newValue) => {
                  setNetMeteringChecked(newValue);
                  setFieldValue("netMeteringEnabled", newValue);
                }}
                tooltip={NET_METERING_TOOLTIP_TEXT}
              />
            </Col>
          </Row>
        )}
      </Row>
    </Container>
  );
}

export default RateSelection;
