import { isDataComplete } from "controllers/pvWatts/pv-watts-utils";
import { displayPercent } from "utils/lib";
import { getElectricBill, getNewMonthlyEnergyCost, getOldMonthlyEnergyCost } from "./bill-info/bill-info-utils";
import { getRateStructureData } from "../rate-selection/rate-selection-utils";

export async function getGridRowData(
  refs,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery,
  pvWattsData,
  rateStructures,
  api,
) {
  if (isDataComplete(pvWattsData)) {
    const initialChargeFraction = 0.5;

    const batteryStartCharge =
      formData.batteryQuantity *
      selectedBattery.capacityKwh *
      initialChargeFraction;

    try {
      const gridRowResponse = await api.post("energyFlow/runEnergyFlow/", {
        refs,
        formData,
        selectedSolarModule,
        selectedInverters,
        selectedBattery,
        rateStructures,
        pvWattsData,
        batteryStartCharge,
      });
      const gridRowDataResponse = gridRowResponse.data;
      const gridRowData = gridRowDataResponse.data;

      const {
        annualPercentRow,
        annualTotalsRow,
        monthlyGridRows,
      } = gridRowData;
      const gridRows = [
        annualPercentRow,
        ...monthlyGridRows,
        annualTotalsRow,
      ];

      return gridRows;
    } catch (error) {
      return null;
    }
  } else {
    return [];
  }
}

export function getPieChartData(
  advancedGridRowData,
  quickDesignRowData,
  formData,
  rateStructures
) {
  if (advancedGridRowData && advancedGridRowData.length > 0) {
    const totalRow = advancedGridRowData[13];
    const solarOffset = totalRow["solarOffset"];
    const netGridExport = totalRow["netGridExport"];
    const gridConsumption = totalRow["gridConsumption"];
    const systemConsumption = totalRow["systemConsumption"];
    const siteConsumption = totalRow["siteConsumption"];
    const timeOfUseFraction =
      quickDesignRowData
        .find((datum) => datum.label === "Time-of-Use Capability")
        .value.replace("%", "") / 100;
    const daysOfGridUse = totalRow["genDays"];
    const oldBill = getOldBill(advancedGridRowData, formData, rateStructures);
    const newBill = getNewBill(advancedGridRowData, formData, rateStructures);
    const billReductionFraction = Math.min(1, 1 - newBill / oldBill);

    const pieChartData = [
      {
        label: `Daily Backup - ${(timeOfUseFraction * 24).toFixed(1)} hours`,
        data: [
          {
            x: "Shortfall",
            y:
              timeOfUseFraction > 1
                ? 0
                : Math.round(100 - timeOfUseFraction * 100),
          },
          {
            x: "Capacity",
            y: Math.round(timeOfUseFraction * 100),
          },
        ],
        unit: "%",
        labelRadius: 55,
      },
      {
        label: `Grid Reliance - ${displayPercent(daysOfGridUse / 365)}`,
        data: [
          {
            x: "On Grid",
            y: daysOfGridUse,
          },
          {
            x: "Off Grid",
            y: 365 - daysOfGridUse < 0 ? 0 : 365 - daysOfGridUse,
          },
        ],
        unit: " days",
        labelRadius: 60,
      },
      {
        label: `Carbon Offset - ${displayPercent(
          solarOffset / siteConsumption
        )}`,
        data: [
          {
            x: "Grid Import",
            y: Math.round(gridConsumption),
          },
          ...(netGridExport > 0
            ? [
                {
                  x: "Net Grid Export",
                  y: Math.round(netGridExport),
                },
              ]
            : []),
          {
            x: "Solar Consumption",
            y: Math.round(systemConsumption),
          },
        ],
        unit: " kWh",
        labelRadius: 45,
      },
      {
        label: `Bill Reduction - ${displayPercent(billReductionFraction)}`,
        data: [
          {
            x: "New Bill %",
            y: Math.round((newBill / oldBill) * 100),
          },
          {
            x: "Bill Reduction %",
            y: Math.round(billReductionFraction * 100),
          },
        ],
        unit: "%",
        isCurrency: true,
        labelRadius: 45,
      },
    ];
    return pieChartData;
  } else return [];
}

const getOldBill = (gridRowData, formData, rateStructures) => {
  const oldRateStructure = getRateStructureData(formData.oldRateStructure, rateStructures);
  const oldFixedCharge = oldRateStructure?.fixedchargefirstmeter || oldRateStructure?.mincharge;
  const oldMonthlyEnergyCost = getOldMonthlyEnergyCost(gridRowData);
  const oldBill = getElectricBill(oldMonthlyEnergyCost, oldFixedCharge);

  return oldBill;
};

const getNewBill = (gridRowData, formData, rateStructures) => {
  const newRateStructure = getRateStructureData(formData.newRateStructure, rateStructures);
  const newFixedCharge = newRateStructure.fixedchargefirstmeter || newRateStructure.mincharge;
  const newMonthlyEnergyCost = getNewMonthlyEnergyCost(gridRowData);
  const newBill = getElectricBill(newMonthlyEnergyCost, newFixedCharge);

  return newBill;
};

export const fetchAndDownloadCsv = async (
  refs,
  formData,
  selectedSolarModule,
  selectedInverters,
  selectedBattery,
  rateStructures,
  pvWattsData,
  batteryStartCharge,
  api
) => {
  try {
    const response = await api.post("energyFlow/getEnergyFlowCsv", {
      refs,
      formData,
      selectedSolarModule,
      selectedInverters,
      selectedBattery,
      rateStructures,
      pvWattsData,
      batteryStartCharge,
    });

    const jsonResponse = response.data;

    if (!jsonResponse.success) {
      throw new Error("Failed to fetch CSV data");
    }

    const csvContent = jsonResponse.data.csvContent;

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "energy_flow_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading CSV:", error);
    throw new Error("Failed to download CSV");
  }
};
